<template>
	<div class="filter">
		<div class="search-header">
			<div>
				<i @click="$router.back(-1)" class="iconfont icon-arrow02"></i>
			</div>
			<div>
				<van-search @click="showSearch = true" placeholder="搜教室、楼层" />
			</div>
		</div>
		<div class="switch-info">
			<div @click="showVagueSearch = true">
				<i class="iconfont icon-fangjian_flour"></i>
				<span>全部房间</span>
				<i class="iconfont icon-fangjian_arrow"></i>
			</div>
		</div>
		<div class="swiper-toggle-page">
			<swiper
				ref="mySwiper"
				:options="swiperOptions"
				:auto-destroy="true"
				:delete-instance-on-destroy="true"
				:cleanup-styles-on-destroy="true"
				@ready="handleSwiperReadied"
				@click-slide="handleClickSlide"
				:autoUpdate="true"
			>
				<swiper-slide v-for="(item, i) in dataArr" :key="i">
					<div
						class="toggle-equipment"
						:class="currentIndex === i ? 'active' : ''"
						@click="toggleEquipment(i, item.id, 0)"
					>
						<i class="iconfont" :class="'icon-fangjian_computer'"></i>
						<span>{{ item.title }}</span>
					</div>
				</swiper-slide>
			</swiper>
		</div>
		<div class="list">
			<van-grid :column-num="2" :gutter="16">
				<van-grid-item v-for="(item, index) in list" :key="index">
					<div class="list-item">
						<div @click="toRoomDetail(item)">
							{{ item.RoomNmae }}
						</div>
						<div @click="toRoomDetail(item)">
							{{ item.RoomNo }}
						</div>
					</div>
				</van-grid-item>
			</van-grid>
		</div>
		<van-popup v-model="showSearch" position="bottom" style="height: 100%">
			<search-block
				:loading="loading"
				:finished="finished"
				:placeholder="placeholder"
				@CancelSearch="showSearch = false"
				@SearchLoad="onLoad"
				@OnSearch="onSearch"
				:dataList="dataList"
				@clickSingle="setData"
				v-model="SearchName"
			></search-block>
		</van-popup>
		<van-popup
			v-if="showVagueSearch"
			round
			v-model="showVagueSearch"
			position="bottom"
			style="height: 70%"
		>
			<div v-if="showVagueSearch" class="v-search-header">
				<span>请选择楼宇楼层</span>
				<span @click="showVagueSearch = false">
					<van-icon name="cross" />
				</span>
			</div>
			<div>
				<van-tabs v-model="active" animated>
					<van-tab v-for="(item, index) in tabTitle" :title="item" :key="index">
						<div class="popup-list">
							<div
								:class="child.active ? 'active' : ''"
								@click="toggleContent(child, index, i)"
								v-for="(child, i) in tabContent[index]"
								:key="i"
							>
								<van-icon name="success" />
								<span>{{ child.title }}</span>
							</div>
						</div>
					</van-tab>
				</van-tabs>
			</div>
		</van-popup>
		<div @click="showMenu = !showMenu" class="return-btn">
			<i class="iconfont icon-control04_menu"></i>
		</div>
		<van-popup
			v-model="showMenu"
			position="bottom"
			round
			class="return-menu"
			:style="{ height: '150px' }"
		>
			<van-grid clickable :column-num="3">
				<van-grid-item @click="$router.go(-1)">
					<div class="rm-item">
						<div><i class="iconfont icon-control_arrow"></i></div>
						<div>返回上一页</div>
					</div>
				</van-grid-item>
				<van-grid-item to="/SmartClassroom/Index">
					<div class="rm-item">
						<div><i class="iconfont icon-shouye_home01"></i></div>
						<div>设备首页</div>
					</div>
				</van-grid-item>
			</van-grid>
			<div @click="showMenu = false" class="popup-close">关闭</div>
		</van-popup>
	</div>
</template>
<script>
import {
	getTerminalRoomList,
	GetTerminalFloor,
	GetTerminalAreaType,
} from '@/api/TerminalEquipment/Terminal'
import SearchBlock from '@/components/SearchBlock.vue'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { Search, Grid, GridItem, Popup, Tab, Tabs } from 'vant'
export default {
	components: {
		Swiper,
		SwiperSlide,
		[Search.name]: Search,
		[Grid.name]: Grid,
		[GridItem.name]: GridItem,
		[Popup.name]: Popup,
		[Tab.name]: Tab,
		[Tabs.name]: Tabs,
		SearchBlock,
	},
	data() {
		return {
			value: '',
			showMenu: false,
			placeholder: '搜教室、名称、楼层',
			searchVal: '',
			checked: false,
			dataList: [],
			loading: false,
			finished: false,
			showSearch: false,
			chooseIndex: 0,
			currentIndex: 0,
			SearchName: null,
			swiperOptions: {
				slidesPerView: 3.1,
				slidesPerGroup: 3,
				spaceBetween: 0,
				observer: true,
			},
			active: 0,
			showVagueSearch: false,
			tabTitle: ['请选择'],
			tabContent: [[], []],
			dataArr: [],
			list: [],
		}
	},
	directives: {
		swiper: directive,
	},
	computed: {
		swiper() {
			return this.$refs.mySwiper.$swiper
		},
	},
	methods: {
		//跳转到房间详情
		toRoomDetail(info) {
			if (this.$route.query.equipment === 'camera') {
				this.$router.push({
					path: '/SmartClassroom/camera',
					query: {
						active: 0,
						terType: info.TerminalType,
					},
				})
			} else {
				this.$router.push({
					path: '/SmartClassroom/Equipement',
					query: {
						active: 0,
						terType: info.TerminalType,
					},
				})
			}
			console.log(info)
			localStorage.RoomInfo = JSON.stringify(info)
		},
		setData(data) {
			let models = {
				GardenId: -2,
				AreaId: -1,
				FloorId: -1,
				RoomId: -1,
				IsDelete: 0,
				status: false,
				FloorRoomName: null,
			}
			if (data.mark == 1) {
				models.FloorId = data.id
			} else {
				models.RoomId = data.id
			}
			this.GetQueryTerminalRoom(models)
			this.showSearch = false
		},
		onLoad() {},
		async onSearch(value) {
			//终端设备列表
			let models = {
				GardenId: -2,
				AreaId: -1,
				FloorId: -1,
				RoomId: -1,
				IsDelete: 0,
				status: false,
				FloorRoomName: value,
			}
			//获取终端设备列表
			let Result = await getGardenAreaFloorRoomList(models)
			if (Result.ResultCode == 200) {
				var dataModel = []
				Result.Data.forEach(x => {
					var modelFloor = {
						name: x.FloorName,
						id: x.FloorId,
						mark: 1,
					}
					var mdoelRoom = {
						name: x.RoomNmae,
						id: x.RoomId,
						mark: 2,
					}
					dataModel.push(modelFloor)
					dataModel.push(mdoelRoom)
				})
				this.dataList = dataModel
				this.$forceUpdate()
			} else if (Result.ResultCode == 206) {
				this.dataList = []
				this.$forceUpdate()
			} else {
				this.dataList = []
				this.$forceUpdate()
			}
		},
		handleSwiperReadied() {},
		handleClickSlide() {},
		//房间楼层筛选
		toggleContent(item, index, Ivalue) {
			let models = {
				GardenId: -2,
				AreaId: -1,
				FloorId: -1,
				RoomId: -1,
				IsDelete: 0,
				status: false,
				FloorRoomName: null,
			}
			//区域条件查询
			if (index == 0) {
				models.AreaId = item.id
				this.toggleEquipment(Ivalue + 1, item.id, 1)
			} else {
				//楼层
				models.AreaId = item.AreaId
				models.FloorId = item.id
				this.GetQueryTerminalRoom(models)
			}

			let FloorModel = {
				GardenId: -2,
				AreaId: item.id,
				IsDelete: 0,
			}

			if (index == 0) {
				this.GetQuertTerminalFloor(FloorModel)
			}
			this.tabContent[index].forEach(element => {
				this.$set(element, 'active', false)
			})
			this.$set(item, 'active', true)
			this.tabTitle[index] = item.title
			if (!(index == 1)) {
				this.tabTitle[index + 1] = '请选择'
				this.active += 1
			}
		},
		//点击区域
		toggleEquipment(i, AreaId, num) {
			this.currentIndex = i
			document.title = this.dataArr[i].title
			this.$router.replace({
				query: Object.assign(this.$route.query, { index: i }),
			})
			let models = {
				GardenId: -2,
				AreaId: AreaId,
				FloorId: -1,
				RoomId: -1,
				IsDelete: 0,
				status: false,
				FloorRoomName: null,
			}
			this.GetQueryTerminalRoom(models)
			if (num === 0) {
				this.tabContent[0].forEach(x => {
					if (x.active === true) {
						x.active = false
					}
				})
				this.tabTitle = ['请选择']
				this.$forceUpdate()
			}
			console.info(this.$refs)
		},
		//列表
		async GetQueryTerminalRoom(models) {
			let Result = await getTerminalRoomList(models)
			if (Result.ResultCode == 200) {
				let roomList = []
				Result.Data.forEach(q => {
					roomList.push({
						AreaId: q.AreaId,
						AreaName: q.AreaName,
						FloorId: q.FloorId,
						FloorName: q.FloorName,
						GardenId: q.GardenId,
						GardenName: q.GardenName,
						RoomId: q.RoomId,
						RoomNmae: q.RoomNmae,
						RoomNo: q.RoomNo,
						TerminalType: q.TerminalType,
					})
				})
				this.list = roomList
				this.$forceUpdate()
			} else if (Result.ResultCode == 206) {
				this.list = []
				this.$forceUpdate()
			} else {
				alert('API接口请求错误')
			}
		},
		//楼层
		async GetQuertTerminalFloor(Model) {
			this.tabContent[1] = []
			let Result = await GetTerminalFloor(Model)
			if (Result.ResultCode == 200) {
				this.tabContent[1].push(...Result.Data)
				this.$forceUpdate()
			} else if (Result.ResultCode == 206) {
				this.tabContent[1] = []
				this.$forceUpdate()
			} else {
				alert('API接口请求错误')
			}
		},
		//区域
		async GetQueryTerminalAreaType() {
			let Result = await GetTerminalAreaType()
			if (Result.ResultCode == 200) {
				let tabContentArr = []
				let arrArea = [{ title: '全部', id: -1 }]
				Result.Data.forEach(x => {
					tabContentArr.push({ active: false, title: x.title, id: x.id })
					arrArea.push(x)
				})
				this.dataArr = arrArea
				this.tabContent[0] = tabContentArr
				this.$forceUpdate()
			} else if (Result.ResultCode == 206) {
				this.dataArr = []
				this.$forceUpdate()
			} else {
				alert('API接口请求错误')
			}
		},
	},
	updated() {},
	mounted() {
		if (this.$route.query && this.$route.query.index >= 3) {
			this.swiper.slideTo(3, 0, false)
			this.currentIndex = parseInt(this.$route.query.index)
		}
		let models = {
			GardenId: -2,
			AreaId: -1,
			FloorId: -1,
			RoomId: -1,
			IsDelete: 0,
			status: false,
			FloorRoomName: null,
		}
		this.GetQueryTerminalRoom(models)
		this.GetQueryTerminalAreaType()
	},
}
</script>
<style lang="scss" scoped>
@import '../../style/public.scss';
.filter {
	width: 100%;
	height: 100%;
	background-color: $bgColor;
	.return-btn {
		position: fixed;
		top: 543px;
		right: 10px;
		width: 40px;
		text-align: center;
		height: 40px;
		line-height: 40px;
		background: #ffffff;
		box-shadow: 0px 0px 6px #c8c8c8;
		border-radius: 50%;
		color: #949494;
		i {
			font-size: 24px;
		}
	}
	.return-menu {
		.rm-item {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;
			text-align: center;
			height: 80px;
			font-size: 12px;
			font-weight: 400;
			color: $titleColor;
			i {
				font-size: 28px;
			}
			> div:first-child {
				margin-bottom: 5px;
			}
		}
		margin-top: 20px;
		.popup-close {
			height: 38px;
			line-height: 38px;
			text-align: center;
			font-size: 17px;
			font-weight: 400;
			color: $titleColor;
			border-top: 1px solid #ebedf0;
		}
	}
	.v-search-header {
		height: 56px;
		padding: 0 24px;
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f4f4f5;
		> span:first-child {
			font-size: 17px;
			font-weight: 400;
			color: $titleColor;
		}
		> span:last-child {
			color: $gray;
			font-size: 22px;
		}
	}
	.search-header {
		display: flex;
		flex-flow: row nowrap;
		justify-content: center;
		align-items: center;
		margin-right: 16px;
		padding-top: 10px;

		> div:first-child {
			width: 28px;
			height: 28px;
			text-align: center;
			line-height: 28px;
			margin-left: 8px;
			color: $titleColor;
			> i {
				font-size: 20px;
			}
		}
		> div:last-child {
			flex: 1;
			.van-search {
				padding: 0;
			}
		}
	}
	.list {
		box-sizing: border-box;
		padding-top: 14px;
		width: 100%;
		height: calc(100% - 150px);
		overflow-y: scroll;
		.van-grid-item__content {
			border-radius: 5px;
			box-shadow: 0px 0px 10px #ebebeb;
		}
	}
	.popup-list {
		height: 500px;
		overflow-y: scroll;
		> div {
			height: 45px;
			line-height: 45px;
			color: #555;
			padding-left: 24px;
			font-size: 13px;
			font-weight: 400;
			color: #555555;

			i {
				display: none;
				margin-right: 5px;
				color: $themeColor;
			}
		}

		> div.active {
			color: $titleColor;
			i {
				display: inline-block;
			}
		}
	}
	.list-item {
		width: 100%;
		height: 100%;
		position: relative;
		.l-sign {
			position: absolute;
			top: -16px;
			right: -10px;
			color: $themeColor;
			opacity: 0.1;
			> i {
				font-size: 60px;
			}
		}
		.l-title {
			font-size: 17px;
			font-weight: 400;
			line-height: 24px;
			color: #2a2a2a;
			margin-bottom: 6px;
		}
		.l-subtitle {
			font-size: 12px;
			font-weight: 400;
			color: #949494;
			margin-bottom: 18px;
		}
		.l-count {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			> div:first-child {
				.count {
					> span {
						display: inline-block;
						font-size: 12px;
						transform: scale(0.95);
						font-weight: 400;
						color: $titleColor;
					}
					> span:first-child {
						font-size: 18px;
						font-weight: 500;
						color: $themeColor;
						transform: unset;
					}
				}
				.close {
					font-size: 11px;
					font-weight: 500;
					color: #949494;
				}
			}
		}
	}
	.swiper-container {
		overflow: visible;
	}
	.swiper-toggle-page {
		margin-left: 16px;
	}
	.toggle-equipment.active {
		background: $themeColor;
		color: #fff;
	}
	.toggle-equipment {
		width: 107px;
		height: 34px;
		line-height: 34px;
		text-align: center;
		box-shadow: 0px 0px 5px rgba(200, 200, 200, 0.5);
		border-radius: 8px;
		font-size: 14px;
		font-weight: 400;
		color: #2a2a2a;
		background: #fff;
		i {
			margin-right: 7px;
		}
	}
	.switch-info {
		display: flex;
		flex-flow: row nowrap;
		justify-content: flex-start;
		align-items: center;
		margin-top: 16px;
		margin-left: 20px;
		margin-bottom: 16px;
		> div {
			display: flex;
			flex-flow: row nowrap;
			justify-content: center;
			align-items: center;
			> i:first-child {
				font-size: 16px;
				color: $themeColor;
				margin-right: 5px;
			}
			span {
				font-size: 15px;
				font-weight: 400;
				color: $titleColor;
			}
			> i:last-child {
				font-size: 12px;
				display: inline-block;
				transform: scale(0.78);
				margin-left: 3px;
				align-self: flex-end;
				padding-bottom: 5px;
			}
		}
	}
}
</style>
<style lang="scss">
@import '../../style/public.scss';
.filter {
	.van-grid-item__content::after {
		border-width: 0;
	}
	.van-search__content {
		background-color: #fff;
		border-radius: 8px;
	}
	.van-popup {
		overflow: hidden;
	}
	.van-cell,
	.van-cell__value--alone {
		color: $gray;
	}
	.van-tabs {
		height: 100%;
		.van-tabs__nav {
			background-color: transparent;
		}
		.van-tabs__wrap {
			height: 34px;
			.van-tab {
				border-radius: 10px 10px 0px 0px;
				flex: inherit;
				padding: 0 20px;
			}
		}
		.van-tabs__content {
			height: calc(100% - 34px);
			._container,
			.scroller {
				height: 100%;
			}
		}
		.van-tabs__line {
			background: $themeColor;
		}
	}
}
</style>
