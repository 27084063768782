import service from "../config.js";
import link from "../link.js";

let ApiUrl = link.IOtApiHong.APIService;

//获取园区 区域 楼层 房间
export const getGardenAreaFloorRoomList = async (valueData) => {
  let responseData = await service({
    url: ApiUrl + "/api/TerminalEquipment/GetQueryGardenFLoorAreaRoom",
    method: "Post",
    data: valueData
  })
  return responseData;
}

//获取园区 区域 楼层 房间
export const getTerminalRoomList = async (valueData) => {
  let responseData = await service({
    url: ApiUrl + "/api/TerminalEquipment/GetQueryTerminalRoom",
    method: "Post",
    data: valueData
  })
  return responseData;
}

//获取 楼层
export const GetTerminalFloor = async (valueData) => {
  let responseData = await service({
    url: ApiUrl + "/api/TerminalEquipment/GetQuertTerminalFloor",
    method: "Post",
    data: valueData
  })
  return responseData;
}

//获取区域
export const GetTerminalAreaType = async () => {
  let responseData = await service({
    url: ApiUrl + "/api/TerminalEquipment/GetQueryTerminalAreaType",
    method: "get"
  })
  return responseData;
}